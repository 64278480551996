import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import { BarLoader } from 'react-spinners';
import { NotificationManager } from 'react-notifications';
import { getOwnershipReport } from 'src/services/ownership';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 150 },
  { id: 'title', label: 'Title' },
  { id: 'type', label: 'Type' },
  { id: 'ownershipPercentage', label: 'Ownership Percentage' },
];

const OwnershipReport = () => {
  const [loading, setLoading] = useState(false);
  const [federalTaxId, setFederalTaxId] = useState('');
  const [empName, setEmpName] = useState('');
  const [ownershipReports, setOwnershipReports] = useState([]);
  const [rows, setRows] = useState([]);

  const styles = {
    cell: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px'
    },
    cellTotal: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px',
      fontWeight: 'bold'
    }
  };

  const handleFederalTaxIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rawValue = event.target.value;
    const numericValue = rawValue.replace(/\D/g, '');

    let formattedValue = '';
    if (numericValue.length > 0) {
      formattedValue = numericValue.slice(0, 3);
      if (numericValue.length > 3) {
        formattedValue += '-' + numericValue.slice(3, 5);
      }
      if (numericValue.length > 5) {
        formattedValue += '-' + numericValue.slice(5, 9);
      }
    }
    setFederalTaxId(formattedValue);
  };

  const fetchOwnershipReport = async () => {
    try {
      setLoading(true);
      const res = await getOwnershipReport(federalTaxId);
      if (res.meta.status !== 200) {
        // NotificationManager.error(res.meta.message);
        return;
      }
      setOwnershipReports(res.data);
      setRows(res.data);
      setEmpName(res?.data[0]?.fullLegalName || '');

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching clients:', error);
    }
  }
  const handleSearch = () => {
    if (!federalTaxId) {
      NotificationManager.error('Please enter Federal Tax ID');
      return;
    }

    fetchOwnershipReport();
  };

  const handleExportReport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([`Federal Tax ID: ${federalTaxId || ''}`]); // Row for Legal Business Name
    worksheet.addRow([
      `Employee Name: ${rows[0]?.fullLegalName || ''}`
    ]); 

    // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      'S.No',
      'State',
      'Business Name',
      'Title',
      'Type',
      'Ownership Percentage'
    ]); // Row for Sale Name

    const federalId = worksheet.getCell('A2');
    const employeeName = worksheet.getCell('A3');

    federalId.font = { bold: true, color: { argb: 'ff1c4e80' } };
    employeeName.font = { bold: true, color: { argb: 'ff1c4e80' } };

    worksheet.getRow(6).eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: 'ff1c4e80' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc6eob4' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });

    worksheet.mergeCells('A2:E2'); // merging for Legal Business Name
    worksheet.mergeCells('A3:E3'); // merging for DBA

    worksheet.columns = [
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
    ];

    rows.forEach((item, itemIndex) => {
      worksheet.addRow([
        +itemIndex + 1,
        item.client?.state,
        item.client?.legalBusinessName,
        item.title,
        item.type,
        item.ownershipPercent
      ]);
    });

    

    // Apply bold styling for headers and total row
    worksheet.getRow(1).font = { bold: true };

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, 'export.xlsx');
  };

  const handleClear = () => {
    setFederalTaxId('');
    setEmpName('');
    setRows([]);
  };

  return (
    <>
      <Box my={2} mx={5}>
        {loading && (
          <div className="loader-wrapper" style={{ textAlign: 'center' }}>
            <BarLoader color={'#1976D2'} loading={loading} width={'94vw'} />
          </div>
        )}
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#1976D2',
            fontSize: '1.5em',
            my: 2
          }}
        >
          Ownership Report
        </Typography>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} md={2}>
            <InputLabel htmlFor="client">Federal Tax Id</InputLabel>
            <TextField
              placeholder={'123-45-6789'}
              variant="outlined"
              fullWidth
              size="small"
              value={federalTaxId}
              onChange={handleFederalTaxIdChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputLabel htmlFor="client">Employee Name</InputLabel>
            <TextField
              placeholder={''}
              variant="outlined"
              fullWidth
              size="small"
              style={{ fontWeight: 'bold', pointerEvents: 'none' }}
              value={empName}
              disabled={true}
            />
          </Grid>
          <Grid item xs={3.5} sm={3} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleClear}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={4} sm={2} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Search
              <SearchIcon />
            </Button>
          </Grid>
          <Grid item xs={0.1} sm={0} md={2}></Grid>
          <Grid item xs={3.8} sm={2} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleExportReport}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Export
              <FileDownloadIcon />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mx={5} mt={1}>
          <Paper sx={{ width: '96vw', overflow: 'hidden' }} elevation={3}>
            <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: '800',
                          color: '#1976D2',
                          border: '1px solid #E3E1D9',
                          borderCollapse: 'collapse',
                          padding: 5,
                          textAlign: 'center'
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((item, itemIndex) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={itemIndex}
                    >
                      <TableCell sx={styles.cell}>{itemIndex + 1}</TableCell>
                      <TableCell sx={styles.cell}>{item?.client?.state}</TableCell>
                      <TableCell sx={styles.cell}>{item?.client?.legalBusinessName}</TableCell>
                      <TableCell sx={styles.cell}>{item.title}</TableCell>
                      <TableCell sx={styles.cell}>{item.type}</TableCell>
                      <TableCell sx={styles.cell}>{item.ownershipPercent}%</TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
    </>
  );
};

export default OwnershipReport;
