import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getClientsWithSalesTaxActive } from 'src/services/clientService';
import { formatClientId, formatNumber } from 'src/utils/helperFunctions';
import moment from 'moment';
import { BarLoader } from 'react-spinners';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmailIcon from '@mui/icons-material/Email';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import ViewSales from '../ViewSales/viewSales';
import { getSaleById, salesReminderEmail } from 'src/services/salesService';
import { NotificationManager } from 'react-notifications';
import { states } from 'src/constants/clients';

interface Column {
  id:
    | 'sno'
    | 'clientID'
    | 'state'
    | 'legalBusinessName'
    | 'doingBusinessAs'
    | 'contactPerson'
    | 'contactEmail'
    | 'contactNumber'
    | 'status'
    | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right';
  //   format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'sno', label: 'S.No' },
  { id: 'clientID', label: 'Client ID' },
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 250 },
  { id: 'doingBusinessAs', label: 'Doing Business As', minWidth: 250 },
  { id: 'contactPerson', label: 'Contact person', minWidth: 150 },
  { id: 'contactEmail', label: 'Contact Email' },
  { id: 'contactNumber', label: 'Contact Number' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: 'Actions' }
];

const SalesTaxReport = () => {
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, 'months')
  );
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [unSortedClients, setUnSortedClients] = useState([]);
  const [viewSale, setViewSale] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [isViewSalesModalOpen, setIsViewSalesModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedState, setSelectedState] = useState('All');
  const [searchValue, setSearchValue] = useState('');

  const styles = {
    cell: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px'
    }
  };

  const sortedSalesTax = () => {
    if (orderBy === '') {
      return clients;
    }
    return clients.slice().sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return (order === 'asc' ? 1 : -1) * aValue.localeCompare(bValue);
      }
      return (order === 'asc' ? 1 : -1) * (aValue - bValue);
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClients = async (date: Date) => {
    try {
      setLoading(true);
      const month = moment(date).format('MMMM');
      const year = +moment(date).format('YYYY');
      const data = await getClientsWithSalesTaxActive({ month, year });
      const clientRows = data.data;
      setClients(clientRows);
      setUnSortedClients(clientRows);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching clients:', error);
    }
  };

  const handleMonthChange = (date) => {
    setSelectedDate(date);
    getClients(date);
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Define columns
    worksheet.columns = [
      { header: 'S.No', key: 'sno', width: 6 },
      { header: 'Client Id', key: 'id', width: 6 },
      { header: 'State', key: 'state', width: 16 },
      { header: 'Legal Business Name', key: 'legalBusinessName', width: 30 },
      { header: 'Doing Business As', key: 'doingBusinessAs', width: 30 },
      { header: 'Contact person', key: 'contactPerson', width: 18 },
      { header: 'Contact Email', key: 'contactEmail', width: 18 },
      { header: 'Contact Number', key: 'contactNumber', width: 18 },
      { header: 'Status', key: 'status', width: 10 }
    ];

    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    // Add rows
    sortedSalesTax().forEach((row) => {
      worksheet.addRow({
        id: formatClientId(+row.id),
        state: row.state,
        legalBusinessName: row.legalBusinessName || '',
        doingBusinessAs: row.doingBusinessAs || '',
        contactPerson: row.salesTaxes?.contactName || '',
        contactEmail: row.salesTaxes?.contactEmail || '',
        contactNumber: row.salesTaxes?.contactNumber || '',
        status: row.sales?.length
          ? !!row.sales[0]?.emailSent
            ? 'Approved'
            : 'Added'
          : 'Not-Added'
      });
    });

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, 'export.xlsx');
  };

  const sendSalesReminderEmail = async (row:any) => {
    try {
      const data = await salesReminderEmail({
        year: new Date(`${selectedDate}`).getFullYear(),
        month: `${new Date(`${selectedDate}`).getMonth() + 1}`,
        clientId: row?.id,
        legalName: row?.legalBusinessName,
        doingBusinessAs: row?.doingBusinessAs,
        email: row?.salesTaxes?.contactEmail
      });
      if (data.meta.status === 200) {
        NotificationManager.success('Email sent successfully');
        await getClients(new Date (`${selectedDate}`));
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const checkEmailSent = (row: any) => {
    if (row?.emailHistories?.length) {
      try{
        const filtered = row.emailHistories.filter((item: any) => {
          const emailData = JSON.parse(item.emailData); // Parse the JSON string// Compare the year and month with the selected date
          return (
            +emailData.year === new Date(`${selectedDate}`).getFullYear() &&
            +emailData.month === new Date(`${selectedDate}`).getMonth() + 1
          )
        })
        if (filtered.length) {
          return true
        } else {
          return false
        }
      }catch(error){
        return false
      }
    }
  };
  
  const handleView = async (id: number) => {
    console.log(clients[id]);
    try {
      setLoading(true);
      const sale = await getSaleById(clients[id].sales[0]?.id);
      setViewSale(sale.data);
      setIsViewSalesModalOpen(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      NotificationManager.error('Sale not found', 'Error', 3000);
    }
  };

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
  };
  const handleStateChange = (value: string) => {
    setSelectedState(value);
  };
  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const handleFilters = (data: any) => {
    const filteredData = data.filter((item: any) => {
      let isMatch = true;

      // Filter based on selectedState
      if (
        selectedState &&
        selectedState !== 'All' &&
        selectedState !== item.state
      ) {
        isMatch = false;
      }

      // Filter based on selectedStatus
      if (isMatch) {
        // Only check status if state matched
        if (
          selectedStatus === 'Approved' &&
          (!item.sales?.length || !item.sales[0]?.emailSent)
        ) {
          isMatch = false;
        } else if (selectedStatus === 'Not-Added' && item.sales?.length) {
          isMatch = false;
        } else if (
          selectedStatus === 'Added' &&
          (!item.sales?.length || item.sales[0]?.emailSent)
        ) {
          isMatch = false;
        } else if (
          selectedStatus &&
          selectedStatus !== 'All' &&
          selectedStatus !== 'Approved' &&
          selectedStatus !== 'Not-Added' &&
          selectedStatus !== 'Added'
        ) {
          isMatch = false;
        }
      }

      // Filter based on searchValue
      if (
        isMatch &&
        searchValue &&
        item.legalBusinessName &&
        !item.legalBusinessName
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isMatch = false;
      }

      return isMatch;
    });

    setClients(filteredData);
  };

  const handleClear = () => {
    setSelectedStatus('All');
    setSelectedState('All');
    setSearchValue('');
  };

  const closeViewSalesModal = () => {
    setIsViewSalesModalOpen(false);
  };

  useEffect(() => {
    try {
      setLoading(true);
      handleFilters(unSortedClients);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error Filtering Records:', error);
    }
  }, [selectedState, selectedStatus, searchValue, selectedDate]);
  useEffect(() => {
    try {
      const date = moment().subtract(1, 'months');
      setLoading(true);
      getClients(date.toDate());
      handleClear();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching clients:', error);
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-wrapper" style={{ textAlign: 'center' }}>
          <BarLoader color={'#1976D2'} loading={loading} width={'97vw'} />
        </div>
      )}
      <Box mx={5}>
      <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#1976D2',
            fontSize: '1.5em',
            my: 2
          }}
        >
          Sales Report
        </Typography>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={2} sx={{ mt: { xs: 0, md: 0 } }}>
              <InputLabel htmlFor="businessFormationDate">Month</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    views={['year', 'month']}
                    openTo="month"
                    value={selectedDate}
                    onChange={handleMonthChange}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'businessFormationDate'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={2} sx={{ mt: { xs: 0, md: 0 } }}>
              <InputLabel htmlFor="state">State</InputLabel>
              <Autocomplete
                id="state"
                options={['All', ...states]}
                // getOptionLabel={(client) => client.legalBusinessName || ''}
                value={selectedState}
                onChange={(_, newValue) => handleStateChange(newValue)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3.5} sx={{ mt: { xs: 0, md: 0 } }}>
              <InputLabel htmlFor="client-search">Client Name</InputLabel>
              <TextField
                sx={{ width: '100%' }}
                id="client-search"
                type="search"
                // label="Search Clients"
                size="small"
                variant="outlined"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={8} md={1.5} sx={{ mt: { xs: 0, md: 0 } }}>
              <InputLabel htmlFor="client">Status</InputLabel>
              <Autocomplete
                id="client"
                options={['All', 'Added', 'Approved', 'Not-Added']}
                // getOptionLabel={(client) => client.legalBusinessName || ''}
                value={selectedStatus}
                onChange={(_, newValue) => handleStatusChange(newValue)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>
            <Grid
              item
              xs={4}
              md={1.5}
              sx={{ mt: { xs: 3, md: 3 }, scale: { xs: 0.6 } }}
            >
              <Button variant="contained" color="warning" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
            {/* <Grid
              item
              xs={4}
              md={1}
              sx={{ mt: { xs: 3, md: 3 }, scale: { xs: 0.6 } }}
            >
              <Button variant="contained" color="secondary" onClick={handleClear}>
                Remind    
                <EmailIcon />
              </Button>
            </Grid> */}
            <Grid
              item
              xs={5}
              md={1}
              sx={{ mt: { xs: 0, md: 3 }, scale: { xs: 0.6 } }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={handleExportToExcel}
              >
                Export
                <FileDownloadIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Paper sx={{ width: '95vw', overflow: 'hidden' }} elevation={3}>
            <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align as any}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: '800',
                          color: '#1976D2',
                          backgroundColor: '#f5f5f5'
                        }}
                        sx={styles.cell}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedSalesTax()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell sx={styles.cell}>{index + 1}</TableCell>
                        <TableCell sx={styles.cell}>
                          {formatClientId(+row.id) || 'N/A'}
                        </TableCell>
                        <TableCell sx={styles.cell}>{row.state}</TableCell>
                        <TableCell sx={styles.cell}>
                          {row.legalBusinessName}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.doingBusinessAs}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.salesTaxes.contactName}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.salesTaxes.contactEmail}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.salesTaxes.contactNumber}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {' '}
                          <Box
                            component="div" // Set the component to "div" for non-clickable behavior
                            sx={{
                              color: row.sales?.length
                                ? !!row.sales[0]?.emailSent
                                  ? 'green'
                                  : '#FFB302'
                                : '#FF3838'
                            }}
                          >
                            {row.sales?.length
                              ? !!row.sales[0]?.emailSent
                                ? 'Approved'
                                : 'Added'
                              : 'Not-Added'}
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...styles.cell,
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          {row.sales?.length > 0 ? (
                            <VisibilityIcon
                              onClick={() => handleView(index)}
                              style={{
                                color: '#1976D2',
                                cursor: 'pointer'
                              }}
                            />
                          ) : (
                            <Button size='small' variant={checkEmailSent(row) ? 'contained' : 'outlined'} sx={{height: '25px'}} onClick={() => {sendSalesReminderEmail(row)}}>{checkEmailSent(row) ? 'Repeat' : 'Remind'}</Button>
                            // <>&nbsp;</>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={clients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
      <ViewSales
        open={isViewSalesModalOpen}
        onClose={closeViewSalesModal}
        viewSales={viewSale}
      />
    </>
  );
};

export default SalesTaxReport;
