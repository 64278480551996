
export const formatNumber = (value) => {
  return isNaN(Number(value)) ? "" : Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};


export const sortTableDataByColumnsAndSearch = (data: any, selectedColumns: string[], searchValue: string) => {

  console.log({ data });
  console.log({ selectedColumns });
  console.log({ searchValue });

  const filteredData = data.filter(item => {
    // Check if any selected column matches the search value
    return selectedColumns.some(column => {
      // Access the value of the current column in the item object
      const columnValue = item[column];

      // Check if the columnValue includes the searchValue (case insensitive)
      return columnValue.toString().toLowerCase().includes(searchValue.toLowerCase());
    });
  });
  return filteredData
}


export const formatClientId = (num) => num.toString().padStart(5, '0');
